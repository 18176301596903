<template lang="html">
  <div>
    <div class="title-area mt-s">
      <h2 class="title-b">설정 / 단말기 관리</h2>
    </div>
    <div class="search-area mt-s">
      <div class="search-item">
        <t-rich-select
          v-if="getUserRole === 'master'"
          :options="companyList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="companyName"
          valueAttribute="companyGuid"
          :hideSearchBox="true"
          v-model="companySearchGuid"
          @change="selectCompany"
          placeholder="고객사/운용부서"
          :clearable="true"
          noResultsText="등록된 고객사가 없습니다"
        >
        </t-rich-select>

        <t-rich-select
          :options="siteList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="siteName"
          valueAttribute="siteGuid"
          :hideSearchBox="true"
          v-model="siteSearchGuid"
          @change="selectSite"
          placeholder="관리구역"
          :clearable="true"
          noResultsText="등록된 관리구역이 없습니다."
        >
        </t-rich-select>

        <t-rich-select
          :options="mapList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="mapName"
          valueAttribute="mapGuid"
          :hideSearchBox="true"
          v-model="mapSearchGuid"
          placeholder="상세위치"
          :clearable="true"
          noResultsText="등록된 상세위치가 없습니다"
        >
        </t-rich-select>

        <t-rich-select
          :options="deviceSearchStatusList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="value"
          valueAttribute="id"
          :hideSearchBox="true"
          v-model="deviceSearchStatus"
          placeholder="단말기상태"
          :clearable="true"
        >
        </t-rich-select>

        <input class="element-input w-100 outline-none" type="text" v-model="deviceSearchName" placeholder="단말기 명">

        <button type="button" class="element-btn bg-blue w-150" @click="searchDeviceList()">검색</button>
      </div>
      <div class="btn-item">
      </div>
    </div>
    <div class="title-area mt-40">
      <h3 class="title-a">총 단말기 수</h3>
      <span class="count-value">{{totalCount}}</span>
      <div class="btn-item">
        <button v-if="getUserRole === 'master'" type="button" class="element-btn bg-blue" @click="registDevice()"><i class="icon-img ic-btn-add"></i>신규 등록</button>
        <button type="button" class="element-btn bg-purple" @click="excelDownload()"><i class="icon-img ic-excel-download"></i>엑셀 다운</button>
        <button v-if="getUserRole === 'master'" type="button" class="element-btn bg-deep-blue" @click="displayBulkRegist()"><i class="icon-img ic-btn-file-upload"></i>대량 등록</button>
      </div>
    </div>
    <div class="table-head mt-xs">
      <div class="btn-item" v-if="getUserRole === 'master'">
        <button type="button" class="btn-table-head" @click="deleteDevice()">
          <span class="btn-txt">선택 삭제</span>
          <i class="icon-img ic-list-select-delete"></i>
        </button>
      </div>
    </div>
    <div class="table-a">
      <table>
        <colgroup>
          <col style="width:60px" v-if="getUserRole === 'master'">
          <col style="width:60px">
          <col style="width:150px">
          <col style="width:100px">
          <col style="width:150px">
          <col style="width:150px">
          <col style="width:120px">
          <col style="width:120px">
          <col style="width:80px">
          <col style="width:120px">
          <col style="width:120px">
          <col style="width:150px">
          <col style="width:60px" v-if="getUserRole === 'master'">
        </colgroup>
        <thead>
        <tr>
          <th scope="col" v-if="getUserRole === 'master'">
            <div class="element-checkbox checkbox-text">
              <input type="checkbox" id="chk-all" class="checkbox-input" v-model="isCheckAll" @click="checkAll($event.target.checked)"><label for="chk-all" class="checkbox-label">전체</label>
            </div>
          </th>
          <th scope="col">No.</th>
          <th scope="col" class="cursor-pointer" @click="settingListOrder('insertDate')"><span class="text-blue-700">등록일자</span><font-awesome-icon :icon="orderSetting.target == 'insertDate' ? (orderSetting.direction == 'desc' ? 'fa-sort-down': 'fa-sort-up') : ''" class="mx-2"></font-awesome-icon></th>
          <th scope="col" class="cursor-pointer" @click="settingListOrder('deviceID')"><span class="text-blue-700">단말ID</span><font-awesome-icon :icon="orderSetting.target == 'deviceID' ? (orderSetting.direction == 'desc' ? 'fa-sort-down': 'fa-sort-up') : ''" class="mx-2"></font-awesome-icon></th>
          <th scope="col" class="cursor-pointer" @click="settingListOrder('deviceName')"><span class="text-blue-700">단말기명</span><font-awesome-icon :icon="orderSetting.target == 'deviceName' ? (orderSetting.direction == 'desc' ? 'fa-sort-down': 'fa-sort-up') : ''" class="mx-2"></font-awesome-icon></th>
          <th scope="col">고객사</th>
          <th scope="col">관리구역</th>
          <th scope="col">상세위치</th>
          <th scope="col">단말상태</th>
          <th scope="col" class="cursor-pointer" @click="settingListOrder('bootDate')"><span class="text-blue-700">최근정보업데이트</span><font-awesome-icon :icon="orderSetting.target == 'bootDate' ? (orderSetting.direction == 'desc' ? 'fa-sort-down': 'fa-sort-up') : ''" class="mx-2"></font-awesome-icon></th>
          <th scope="col">펌웨어 버전/업데이트</th>
          <th scope="col">WiFi DB/업데이트</th>
          <th scope="col" v-if="getUserRole === 'master'">수정</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(dItem, idx) in deviceList" :key="idx">
          <td v-if="getUserRole === 'master'">
            <div class="element-checkbox">
              <input type="checkbox" :id="'check_' + idx" class="checkbox-input" @change="checkItem($event)" v-model="dItem.selector"><label :for="'check_' + idx" class="checkbox-label">선택</label>
            </div>
          </td>
          <td>{{ totalCount - idx - (limit * ( currentPage - 1 )) }}</td>
          <td>{{ dItem.insertDate | moment('YYYY-MM-DD HH:mm:ss')}}</td>
          <td>{{ dItem.deviceID }}</td>
          <td><span class="txt-blue">{{ dItem.deviceName }}</span></td>
          <td>{{ dItem.companyName }}</td>
          <td>{{ dItem.siteName }}</td>
          <td>{{ dItem.mapName }}</td>
          <td>{{ deviceStatusList[dItem.deviceStatus] }}</td>
          <td>{{ dItem.bootDate | moment('YYYY-MM-DD HH:mm:ss') }}</td>
          <td><div :class="getUserRole === 'master' || getUserRole === 'admin' ? 'cursor-pointer' : ''" @click="openUpdateCommandSend('firmware',dItem)">v{{ dItem.deviceFWVersion }} <i class="icon-img ic-system-update"></i></div></td>
          <td><div :class="getUserRole === 'master' || getUserRole === 'admin' ? 'cursor-pointer' : ''" @click="openUpdateCommandSend('wifi',dItem)">v{{ dItem.deviceWifiVersion }} <i class="icon-img ic-system-update"></i></div></td>
          <td v-if="getUserRole === 'master'"><button type="button" class="btn-modify" @click="updateDevice(dItem.deviceGuid)"><i class="icon-img ic-txt-list-edit"></i><span class="for-a11y">수정하기</span></button></td>
        </tr>
        </tbody>
      </table>
    </div>
    <paging-component :totalCount="totalCount" :limit="limit" :currentPage="currentPage" @chgCurrentPage="chgCurrentPage"></paging-component>



    <!-- 단말 대량 등록 다이얼 로그 START -->
    <div class="module-dimmed" v-if="showBulkRegist">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">단말기 대량 등록</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <p class="form-guide txt-blue">* [샘플 다운받기] 다운로드 후 작성하신 파일을 업로드해주세요</p>
              <div class="form-item mt-s">
                <span class="form-label">고객사/운용부서 <em class="essential">*</em></span>
                <div class="form-inp">
                  <t-rich-select
                    :options="companyList"
                    class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
                    textAttribute="companyName"
                    valueAttribute="companyGuid"
                    :hideSearchBox="false"
                    v-model="companyGuid"
                    :fixedClasses="richSelectXL"
                  >
                  </t-rich-select>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="companyGuid=='0'">필수 입력 사항 입니다.</p>
                </div>
              </div>
              <div class="form-btn mt-25">
                <button type="button" class="element-btn bg-blue size-xs round" @click="downloadSample()">샘플 다운받기</button>
              </div>
              <div class="form-item">
                <span class="form-label">엑셀파일 업로드<em class="essential">*</em></span>
                <div class="form-inp">
                  
                  <div v-if="bulkFileInfo" class="mt-5 px-4 py-4 bg-white border border-sm-light-periwinkle border-dotted rounded-xl w-full">
                    <div class="text-sm font-bold txt-blue cursor-pointer text-right mt-1 mb-5" @click="retryBulkFile()">새로올리기</div>
                    <div class="block text-center mb-2">{{ bulkFileInfo.name }}</div>
                    
                  </div>
                  
                  <div v-else class="box-file-upload" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" class="w-px h-px opacity-0 overflow-hidden absolute" @change="registBulkFile()" id="bulkFile" ref="bulkFile" accept=".xls, .xlsx" />
                  
                    <label for="bulkFile" class="block cursor-pointer">
                      <div class="flex flex-col justify-center items-center">
                        <i class="icon-img ic-m-file-upload"></i>
                        <span class="file-desc">이곳을 클릭하여 파일 업로드 or 파일 드래그</span>
                        <span class="file-name">지원 파일 확장자 *.xls, *.xlsx</span>
                      </div>
                    </label>
                  </div>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="!bulkFileInfo">필수 입력 사항 입니다.</p>
                </div>
              </div>
              <div class="red-box mt-s">
                <p class="title">체크사항</p>
                <p class="desc-a">1. <strong>[샘플 다운받기]</strong>를 클릭하여 샘플파일을 다운받은 후 양식에 맞게 업로드해주세요.<br>
                  2. 각 항목에 값을 입력한 후 저장합니다.<br>
                  - 저장 시 파일형식이 [Excel 97 - 2003 통합문서 (*.xls, *.xlsx)]으로 지정되어있는지 확인해주세요.<br>
                  3. 고객사/운용부서를 먼저 등록하신 후 등록된 관리구역명을 선택해주세요.<br>
                  4. 파일첨부를 하시고 등록하기 버튼을 누릅니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeBulkRegist()">취소</button>
          <!-- <button type="button" class="element-btn bg-blue size-lg" @click="deviceBulkRegist()" :disabled="checkBulkRegistDisable()">완료</button> -->
          <button type="button" class="element-btn bg-blue size-lg" @click="deviceBulkRegist()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeBulkRegist()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 단말 대량 등록 다이얼로그  END -->

    <!-- 삭제 다이얼로그 START -->
    <div class="module-dimmed" v-if="showDeleteDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="text-lg font-bold text-red-700 text-center">다시 한번 확인해 주세요.</p>
            <div class="red-box mt-s">
              <p class="title">체크사항</p>
              <p class="desc-a">
                1. 삭제된 내용은 복구 할 수 없습니다.<br>
              </p>
            </div>
            <p class="confirm-text">관련된 내용이 삭제됩니다.<br>삭제하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="deleteDeviceCancel()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="deleteDeviceAction()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="deleteDeviceCancel()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 삭제 다이얼로그 END -->

    <!-- 업데이트 다이얼로그 START -->
    <!-- <div class="module-dimmed" v-if="showUpdateDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">{{updateType === 'firmware' ? '펌웨어' : 'WiFi DB' }}를 업데이트 합니다.<br>업데이트 하시겠습니까?</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeUpdateCommandSend()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="excuteUpdateCommandSend()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeUpdateCommandSend()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div> -->
    <div class="module-dimmed" v-if="showUpdateDialog">
      <div class="module-dialog dialog-lg">
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="px-10">
              <p class="text-center text-4xl text-gray-700 font-black">{{updateType === 'firmware' ? '펌웨어' : 'WiFi DB' }} 업데이트</p>

              <p class="text-xl text-gray-700 font-black mt-10">단말기 정보</p>

              <div class="grid grid-cols-2 gap-5 mt-5">
                <div>
                  <p class="text-lg font-bold text-gray-400">단말 ID</p>
                  <p class="text-lg text-gray-700 mt-3">innertron-{{deviceInfo.deviceID}}</p>
                </div>
                
                <div>
                  <p class="text-lg font-bold text-gray-400">펌웨어 버전</p>
                  <p class="text-lg text-gray-700 mt-3">v{{ updateType === 'firmware' ? deviceInfo.deviceFWVersion : deviceInfo.deviceWifiVersion }}</p>
                </div>
              </div>

              <div class="mt-6">
                <div>
                  <p class="text-lg font-bold text-gray-400">설치 위치</p>
                  <p class="text-lg text-gray-700 mt-3">{{deviceInfo.siteName}} > {{deviceInfo.mapName}}</p>
                </div>
              </div>

              <div class="grid grid-cols-3 gap-5 mt-6">
                <div>
                  <p class="text-lg font-bold text-gray-400">상태</p>
                  <p class="text-lg mt-3" :class="deviceInfo.deviceStatus === 'regist'? 'text-green-700' : 'text-red-700'">{{deviceStatusList[deviceInfo.deviceStatus]}}</p>
                </div>
                
                <div>
                  <p class="text-lg font-bold text-gray-400">최근 확인</p>
                  <p class="text-lg text-gray-700 mt-3">{{deviceInfo.aliveDate | moment('YYYY-MM-DD HH:mm:ss') }}</p>
                </div>

                <div>
                  <p class="text-lg font-bold text-gray-400">단말기 연락처</p>
                  <p class="text-lg text-gray-700 mt-3">{{ deviceInfo.deviceMsisdn | phoneNumber }}</p>
                </div>
              </div>

              <p class="text-center text-xl text-gray-700 font-black mt-10">{{updateType === 'firmware' ? '펌웨어' : 'Wifi DB'}} 파일</p>

              <div class=" w-full border border-solid border-gray-300 py-5 flex justify-center items-center mt-3 rounded-lg">
                <i class="icon-img ic-firmware mx-5"></i>
                <span class="link-firmware">{{updateType === 'firmware' ? firmwareInfo.device.fileOrgName : firmwareInfo.wifi.fileOrgName}}</span>
              </div>

              <div class="text-center mt-5">
                <p class="text-gray-700">{{updateType === 'firmware' ? '펌웨어' : 'Wifi DB'}} 버젼 : v{{updateType === 'firmware' ? firmwareInfo.device.firmwareVersion : firmwareInfo.wifi.firmwareVersion}}</p>
                <p class="text-gray-700">파일 용량 : {{updateType === 'firmware' ? firmwareInfo.device.fileSize : firmwareInfo.wifi.fileSize  | byteToString}}</p>
              </div>


            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeUpdateCommandSend()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="excuteUpdateCommandSend()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeUpdateCommandSend()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 업데이트 다이얼로그 END -->

  </div>
</template>

<script>
import PagingComponent from "@/components/elements/paging"
import { requestApi, requestGetDownload, requestPostDownload } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import { richSelectXL } from "@/references/fixedClass"

import { deviceStatusList } from "@/references/config"

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getCompanyListAll();
    if(this.getCompanyGuid != "0") {
      this.companySearchGuid = this.getCompanyGuid
      this.getSiteListAll()
    }
    this.getDeviceList();
  },
  components: {
    PagingComponent
  },
  computed: {
    ...mapGetters(["getIsOverlay","getUserRole","getCompanyGuid"]),
  },
  data () {
    return {
      richSelectXL,

      limit: 15,
      currentPage: 1,
      totalCount: 0,
      deviceList: [],

      selectedItem: [],
      isCheckAll: false,

      companyGuid:'',
      siteGuid:'',
      mapGuid:'',
      deviceName:'',
      deviceStatus:'',
      deviceStatusList,

      showBulkRegist: false,
      companyList: [],
      bulkFileInfo: null,

      companySearchGuid: '',
      siteList: [],
      siteSearchGuid: '',

      mapList: [],
      mapSearchGuid: '',
      deviceSearchStatus: '',
      deviceSearchName: '',

      deviceSearchStatusList: [
        {id:'unknown',value:'미설치'},
        {id:'regist',value:'온라인'},
        {id:'unregist',value:'오프라인'},
      ],

      showDeleteDialog: false,
      
      showUpdateDialog: false,
      updateType: 'firmware',
      updateTarget: '00000000000',
      deviceInfo: {},
      firmwareInfo: {
        "device": {
          "firmwareVersion": "0.0.0",
          "fileSize": 0,
          "insertDate": "0000-00-00 00:00:00",
          "fileOrgName": ""
        },
        "wifi": {
          "firmwareVersion": "0.0.0",
          "fileSize": 0,
          "insertDate": "0000-00-00 00:00:00",
          "fileOrgName": ""
        }
      },

      orderSetting: {target: "insertDate", direction: "desc"}
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    checkAll (checked) {
      this.isCheckAll = checked;
      for (let i in this.deviceList) {
        this.deviceList[i].selector = this.isCheckAll;
      }
    },
    checkItem () {
      for (let i in this.deviceList) {
        if(! this.deviceList[i].selector) {
          this.isCheckAll = false;
          return;
        } else {
          this.isCheckAll = true;
        }
      }
    },

    settingListOrder(target) {
      if(target === this.orderSetting.target) this.orderSetting.direction = this.orderSetting.direction === 'desc' ? 'asc' : 'desc'
      else {
        this.orderSetting.target = target
        this.orderSetting.direction = 'desc'
      }

      this.getDeviceList();
    },

    selectCompany () {
      this.siteSearchGuid = ''
      this.getSiteListAll()
    },
    selectSite () {
      this.mapSearchGuid = ''
      this.getMapListAll()
    },

    selectMap () {
    },

    registDevice () {
      this.$router.push({name:"DeviceRegist"})
    },
    updateDevice (guid) {
      this.$router.push({name: "DeviceUpdate",query:{guid}})
    },

    searchDeviceList () {
      this.currentPage = 1
      this.getDeviceList()
    },
    async getDeviceList () {
      let reqObj = {}
      reqObj.target = `/device/list`;
      reqObj.method = "get";
      reqObj.params = {
        "companyGuid": this.companySearchGuid ? this.companySearchGuid : '0',
        "siteGuid": this.siteSearchGuid ? this.siteSearchGuid : '0',
        "mapGuid": this.mapSearchGuid ? this.mapSearchGuid : '0',
        "deviceName": this.deviceSearchName ? this.deviceSearchName : '',
        "deviceStatus": this.deviceSearchStatus ? this.deviceSearchStatus : '',
        "orderTarget": this.orderSetting.target ? this.orderSetting.target : 'insertDate',
        "orderDirection": this.orderSetting.direction ? this.orderSetting.direction : 'desc',
        "offset": this.currentPage - 1,
        "limit": this.limit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        let tempList = result.msg.retData.content
        let addSelector = tempList.map(data => ({ ...data, selector: false}))
        this.deviceList = [...addSelector]

        this.totalCount = result.msg.retData.totalElements
      }
    },


    chgCurrentPage (num) {
      this.currentPage = num
      this.getDeviceList()
    },

    async downloadSample () {
      let fileName = "sample.xlsx";
      let url = `/files/downloadPath/common/${fileName}`;
      let result = await requestGetDownload(url, fileName);
      console.log(result)
    },

    async getCompanyListAll () {
      let reqObj = {}
      reqObj.target = "/company/listall";
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.companyList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companyList = result.msg.retData
      }
    },

    async getSiteListAll () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.companySearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.siteList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteList = result.msg.retData
      }
    },

    async getMapListAll () {
      let reqObj = {}
      reqObj.target = `/map/list/${this.siteSearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.mapList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapList = result.msg.retData
      }
    },


    displayBulkRegist () {
      this.getCompanyListAll()
      this.showBulkRegist = true;
    },
    closeBulkRegist () {
      this.companyGuid = this.getCompanyGuid;
      this.bulkFileInfo = null
      this.showBulkRegist = false;
    },

    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.bulkFile.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    onChange() {
      this.bulkFileInfo = this.$refs.bulkFile.files[0];
    },
    registBulkFile () {
      this.onChange()
    },
    retryBulkFile () {
      this.bulkFileInfo = null
    },
    checkBulkRegistDisable () {
      let retVal = true;
      if(this.companyGuid !== "" && this.bulkFileInfo !== null) retVal = false;
      return retVal;
    },
    async deviceBulkRegist () {
      console.log(this.companyGuid)
      if(this.companyGuid == '0') {
        this.chgIsAlert({status:true,string:'고객사/운용부서를 선택해 주세요.'})
        return;
      }

      if(this.bulkFileInfo == null) {
        this.chgIsAlert({status:true,string:'엑셀파일을 확인해 주세요.'})
        return;
      }

      this.chgIsOverlay(true);
      let reqObj = {};
      reqObj.target = "/device/upload";
      reqObj.method = "post";
      reqObj.params = {};

      const frm = new FormData();
      frm.append("companyGuid", this.companyGuid);
      frm.append("deviceFile", this.bulkFileInfo);

      reqObj.data = frm;
      reqObj.headers = { accept: "application/json" };

      let result = await requestApi(reqObj);
      if (result.msg.retStatus && result.msg.retCode === 1000) {
        this.bulkFileInfo = null
        this.showBulkRegist = false;

        this.currentPage = 1
        this.getDeviceList()
      }

      else {
        if(result.msg == 'duplication') this.chgIsAlert({status:true,string:'중복 아이디가 있습니다.'})
        else if(result.msg == 'duplist') this.chgIsAlert({status:true,string:'파일에 중복 아이디가 있습니다.'})
        else this.chgIsAlert({status:true,string:'등록 오류 입니다.'})
      }
      this.chgIsOverlay(false);
    },

    async excelDownload () {
      let url = `/device/excelDownload`;
      let data = {
        "companyGuid": this.companySearchGuid ? this.companySearchGuid : '0',
        "siteGuid": this.siteSearchGuid ? this.siteSearchGuid : '0',
        "mapGuid": this.mapSearchGuid ? this.mapSearchGuid : '0',
        "deviceName": this.deviceSearchName ? this.deviceSearchName : '',
        "deviceStatus": this.deviceSearchStatus ? this.deviceSearchStatus : '',
      };

      let result = await requestPostDownload(url, data, "단말 목록.xlsx");
      console.log(result)
    },


    deleteDeviceCancel () {
      this.showDeleteDialog = false
    },

    deleteDevice () {
      this.selectedItem = [];
      for (let i in this.deviceList) {
        if(this.deviceList[i].selector) {
          this.selectedItem.push({"deviceGuid":this.deviceList[i].deviceGuid});
        }
      }

      if(this.selectedItem.length > 0) {
        this.showDeleteDialog = true
      }
    },
    async deleteDeviceAction () {
      let reqObj = {}
      reqObj.target = `/device/deleteList`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = this.selectedItem
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'삭제 했습니다.'})
        
        this.showDeleteDialog = false
        this.isCheckAll = false
        this.getDeviceList()
      }
    },
    async getLastRestartDeive () {
      let reqObj = {}
      reqObj.target = `/device/chkRestar/${this.deviceInfo.deviceGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        // console.log(result.msg.retData)
        return result.msg.retData
      }
      else return 0
    },
    async getFirmwareInfo (firmwareType) {
      let reqObj = {}
      reqObj.target = `/firmware/info/${firmwareType}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData != null) this.firmwareInfo[firmwareType] = result.msg.retData;
      }
    },

    // openUpdateCommandSend (item) {
    //   console.log(item)
    // }
    async openUpdateCommandSend (type, item) {
      if(this.getUserRole === 'member' || this.getUserRole === 'agent') {
        return;
      }

      if(item.deviceMsisdn == "00000000000") {
        this.chgIsAlert({status:true,string:'단말의 착신번호가 확인되지 않았습니다.'})
        return;
      }

      if(item.deviceStatus != 'regist') {
        this.chgIsAlert({status:true,string:'단말이 등록 상태가 아닙니다.'})
        return;
      }


      this.deviceInfo = item

      let lastRestart = await this.getLastRestartDeive();
      let nowDate = new Date().getTime()

      let chkSecond = 60
      if(lastRestart.type == 'Firmware') chkSecond = 180

      if(lastRestart.time + (chkSecond * 1000) > nowDate) {
        this.chgIsAlert({status:true,string:`${Math.round((lastRestart.time + (chkSecond * 1000) - nowDate ) / 1000)} 초 후에 업데이트 가능 합니다.`})
        return;
      }


      this.getFirmwareInfo("device")
      this.getFirmwareInfo("wifi")

      this.updateType = type
      this.updateTarget = item.deviceMsisdn
      this.showUpdateDialog = true
    },
    closeUpdateCommandSend () {
      this.deviceInfo = {}
      this.updateTarget = '00000000000'
      this.showUpdateDialog = false
    },
    async excuteUpdateCommandSend () {
      let reqObj = {}
      reqObj.target = `/sms/send`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "deviceID": this.deviceInfo.deviceID,
        "deviceMsisdn": this.deviceInfo.deviceMsisdn,
        "smsType": this.updateType === 'firmware' ? "Firmware" : "Wi-Fi"
      }

      reqObj.headers = { "accept":"application/json" };
      let result = await requestApi(reqObj);
      if(result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'전송했습니다.'})
        
        this.updateTarget = '00000000000'
        this.showUpdateDialog = false
      }
    },

  }
}
</script>